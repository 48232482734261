import "bootstrap-icons/font/bootstrap-icons.css";
import React from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  defer,
  Navigate,
  redirect,
  RouterProvider,
} from "react-router-dom";
import * as api from "./api";
import "./index.scss";
import "./custom-bootstrap.scss";
import ErrorBoundary from "./ErrorBoundary";
import Explore from "./Explore";
import Home from "./Home";
import Login from "./Login";
import Onboarding from "./Onboarding";
import reportWebVitals from "./reportWebVitals";
import Wizard from "./Wizard";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "",
        element: <Navigate to="/home" />,
      },
      {
        path: "onboarding",
        element: <Onboarding.Root />,
        children: [
          {
            path: "",
            element: <Navigate to="intro" />,
          },
          {
            path: "intro",
            element: <Onboarding.Introduction />,
            loader: () => sessionStorage.getItem("onboarding.name"),
            action: async ({ request }: { request: Request }) => {
              const formData = await request.formData();
              const name = formData.get("name")?.toString();

              if (!name) throw new Error("Malformed name");

              sessionStorage.setItem("onboarding.name", name);

              return redirect("../destinations");
            },
          },
          // TODO: Refactor forms below to use actions instead of writing to
          // sessionStorage directly.
          {
            path: "destinations",
            element: <Onboarding.DestinationInput />,
            loader: api.getDestinations,
          },
          {
            path: "rate",
            element: <Onboarding.Rate />,
          },
          {
            path: "register",
            element: <Onboarding.Register />,
            action: async ({ request }: { request: Request }) => {
              const formData = await request.formData();
              const name = formData.get("name")?.toString();
              const email = formData.get("email")?.toString();
              const password = formData.get("password")?.toString();
              const ratings = formData
                .getAll("ratings")
                .map((item) => JSON.parse(item.toString()));

              if (!name || !email || !password)
                throw new Error("Malformed payload");

              await api.createUser(name, email, password, ratings);

              return redirect("/home");
            },
          },
        ],
      },
      {
        path: "login",
        element: <Login />,
        action: async ({ request }: { request: Request }) => {
          const formData = await request.formData();
          const email = formData.get("email")?.toString();
          const password = formData.get("password")?.toString();

          if (!email || !password) throw new Error("Malformed credentials");

          await api.login(email, password);

          return redirect("/");
        },
      },
      {
        path: "logout",
        loader: async () => await api.logout(),
        element: <Navigate to="/login" />,
      },
      {
        path: "home",
        element: <Home />,
        loader: async () =>
          defer({
            user: api.getUser(),
            trips: api.getTrips(),
          }),
      },
      {
        path: "wizard",
        element: <Wizard />,
        loader: async () => ({
          tags: await api.getAllDestinationTags(),
          user: await api.getUser(),
        }),
      },
      {
        path: "explore",
        element: <Explore />,
        loader: async ({ request }: { request: Request }) => {
          const url = new URL(request.url);
          const location = url.searchParams.get("location") || undefined;
          const duration =
            Number(url.searchParams.get("duration")) || undefined;
          const tags = url.searchParams.getAll("tags");
          console.log(tags);

          return defer({
            data: api.getRecommendedTripsWithDetails(location, duration, tags),
          });
        },
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
