import { ProgressBar } from "react-bootstrap";

interface IProps {
  numSegments: number;
  currentSegment: number;
  progress: number;
  className?: string;
  style?: React.CSSProperties;
}

export default function SegmentedProgressBar(props: IProps) {
  const { numSegments, currentSegment, progress, className, style } = props;

  return (
    <div className={`segmented-progress-bar d-flex ${className}`} style={style}>
      {[...Array(numSegments).keys()].map((n) => (
        <ProgressBar
          className="flex-fill"
          style={{ height: "inherit" }}
          now={n < currentSegment ? 100 : currentSegment < n ? 0 : progress}
          key={n}
        />
      ))}
    </div>
  );
}
