import React, { useRef, useState } from "react";
import { Form as RouterForm, useLoaderData, useSubmit } from "react-router-dom";
import { User } from "./api";
import DurationSearchCard from "./DurationSearchCard";
import FriendsSearchCard from "./FriendSearchCard";
import LocationSearchCard, { LocationValue } from "./LocationSearchCard";
import TagSearchCard from "./TagSearchCard";

export default function Wizard() {
  const { tags, user } = useLoaderData() as { tags: string[]; user: User };
  const [visibleCard, setVisibleCard] = useState(0);
  const [location, setLocation] = useState<LocationValue>("anywhere");
  const [duration, setDuration] = useState(3);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const formRef = useRef(null);
  const submit = useSubmit();

  return (
    <div className="vh-100 overflow-hidden">
      <LocationSearchCard
        show={0 <= visibleCard}
        summary={1 <= visibleCard}
        value={location}
        onChange={setLocation}
        onClick={(e) => {
          setVisibleCard(0);
          e.preventDefault();
        }}
        onSubmit={() => setVisibleCard(1)}
        style={{ top: "1rem" }}
        name={user.name}
      />
      <DurationSearchCard
        show={1 <= visibleCard}
        summary={2 <= visibleCard}
        value={duration}
        onChange={setDuration}
        onClick={() => setVisibleCard(1)}
        onSubmit={() => setVisibleCard(2)}
        className="soft-shadow position-absolute"
        style={{
          top: "5rem",
          height: "calc(100% - 5rem)",
        }}
      />
      <TagSearchCard
        show={2 <= visibleCard}
        summary={3 <= visibleCard}
        tags={tags}
        value={selectedTags}
        onChange={setSelectedTags}
        onClick={() => setVisibleCard(2)}
        onSubmit={() => setVisibleCard(3)}
        className="soft-shadow position-absolute"
        style={{
          top: "10rem",
          height: "calc(100% - 10rem)",
        }}
      />
      <FriendsSearchCard
        show={3 <= visibleCard}
        summary={4 <= visibleCard}
        onClick={() => setVisibleCard(3)}
        onSubmit={() => submit(formRef.current)}
        className="soft-shadow position-absolute"
        style={{
          top: "15rem",
          height: "calc(100% - 15rem)",
        }}
      />
      <RouterForm method="get" action="/explore" ref={formRef}>
        <input name="location" value={location} readOnly hidden />
        <input name="duration" value={duration} readOnly hidden />
        {selectedTags.map((tag) => (
          <input name="tags" value={tag} readOnly hidden />
        ))}
      </RouterForm>
    </div>
  );
}
