import React from "react";

export interface IProps {
  show: boolean;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  summary: boolean;
  summaryElement?: React.ReactNode;
  children?: React.ReactNode;
}

export default function OffcanvasCard(props: IProps) {
  const { show, className, style, onClick, summary } = props;

  return (
    <div
      className={`p-4 offcanvas-card ${
        summary ? "bg-primary text-light" : "bg-white"
      } ${show ? "" : "hidden"} ${className || ""}`}
      style={style}
      onClick={onClick}
    >
      {summary ? props.summaryElement : props.children}
    </div>
  );
}

OffcanvasCard.defaultProps = {
  show: false,
  summary: false,
};
