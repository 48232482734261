import React from "react";
import OffcanvasCard, { IProps as OffcanvasCardIProps } from "./OffcanvasCard";
import spainImg from "./assets/spain.png";
import europeImg from "./assets/europe.png";
import worldImg from "./assets/world.png";
import { Button, Card } from "react-bootstrap";

export type LocationValue = "spain" | "europe" | "anywhere";

interface IProps extends OffcanvasCardIProps {
  value: LocationValue;
  name: string;
  onChange: (value: LocationValue) => void;
  onSubmit: () => void;
}

export default function LocationSearchCard(props: IProps) {
  const { value, name, onChange, onSubmit, ...cardProps } = props;

  return (
    <OffcanvasCard
      {...cardProps}
      summaryElement={
        <div className="d-flex justify-content-between fs-5 opacity-75">
          <b>Location</b>
          <span className="text-capitalize">{value}</span>
        </div>
      }
    >
      <div className="d-flex flex-column h-100">
        <h1 className="w-100 mb-3">Where to, {name}?</h1>
        <div className="d-block w-100 mt-5">
          <div className="row gx-3">
            <div className="col-6">
              <Card
                className={`text-center border border-4 ${
                  value === "spain" ? "border-primary" : "border-white"
                }`}
                style={{
                  background: `url(${spainImg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  boxShadow: "inset 0 0 0 2000px rgba(255, 255, 255, 0.3)",
                }}
                onClick={() => onChange("spain")}
              >
                <span className="my-5">Spain</span>
              </Card>
            </div>
            <div className="col-6">
              <Card
                className={`text-center border border-4 ${
                  value === "europe" ? "border-primary" : "border-white"
                }`}
                style={{
                  background: `url(${europeImg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  boxShadow: "inset 0 0 0 2000px rgba(255, 255, 255, 0.3)",
                }}
                onClick={() => onChange("europe")}
              >
                <span className="my-5">Europe</span>
              </Card>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <Card
                className={`text-center border border-4 ${
                  value === "anywhere" ? "border-primary" : "border-white"
                }`}
                style={{
                  background: `url(${worldImg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  boxShadow: "inset 0 0 0 2000px rgba(255, 255, 255, 0.3)",
                }}
                onClick={() => onChange("anywhere")}
              >
                <span className="my-5">Anywhere</span>
              </Card>
            </div>
          </div>
        </div>
        <Button
          className="w-100 mt-auto"
          onClick={(e) => {
            onSubmit();
            e.stopPropagation();
          }}
        >
          Next
        </Button>
      </div>
    </OffcanvasCard>
  );
}
