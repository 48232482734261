import React, { useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import OffcanvasCard, { IProps as OffcanvasCardIProps } from "./OffcanvasCard";

interface IProps extends OffcanvasCardIProps {
  tags: string[];
  value: string[];
  onChange: (value: string[]) => void;
  onSubmit: () => void;
}

export default function TagSearchCard(props: IProps) {
  const { tags, value, onChange, onSubmit, ...cardProps } = props;
  const [tagStatuses, setTagStatuses] = useState(
    Object.fromEntries(tags.map((tag) => [tag, value.includes(tag)]))
  );
  const selectedCount = value.length;

  useEffect(() => {
    // Transform the {tag: true | false} tagStatuses object into an array of
    // active tags.
    onChange(
      Object.entries(tagStatuses)
        .map(([tag, status]) => (status ? tag : null))
        .filter(Boolean) as string[]
    );
  }, [tagStatuses, onChange]);

  return (
    <OffcanvasCard
      {...cardProps}
      summaryElement={
        <div className="d-flex justify-content-between fs-5 opacity-75">
          <b>Interests</b>
          <span>{selectedCount > 0 ? `${selectedCount} selected` : "All"}</span>
        </div>
      }
    >
      <div className="d-flex flex-column h-100">
        <h1 className="w-100 mb-3">Looking for anything in particular?</h1>
        <p className="text-muted">
          This is optional. If you do not select anything, we will consider all
          possible trip types.
        </p>
        <div className="d-block mt-4">
          {tags.map((tag) => (
            <Badge
              pill
              key={tag}
              bg={tagStatuses[tag] ? "primary" : "secondary"}
              className="me-2 mt-2"
              style={{
                transition: "ease-in-out 0.1s",
              }}
              onClick={() => {
                setTagStatuses({ ...tagStatuses, [tag]: !tagStatuses[tag] });
              }}
            >
              {tag}
            </Badge>
          ))}
        </div>
        <Button
          className="w-100 mt-auto"
          onClick={(e) => {
            onSubmit();
            e.stopPropagation();
          }}
        >
          Next
        </Button>
      </div>
    </OffcanvasCard>
  );
}
