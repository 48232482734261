import React, { useEffect } from "react";
import { Badge, Card, Row } from "react-bootstrap";
import {
  Await,
  useAsyncValue,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import { Trip, User } from "./api";
import placeholder from "./assets/placeholder.png";

function LoadingTripCarousel() {
  return (
    <Row
      className="m-0 ps-4 flex-nowrap overflow-scroll hide-scrollbar placeholder-glow"
      style={{ scrollbarWidth: "none" }}
    >
      {[...Array(3)].map((_, idx) => (
        <Card
          className="text-align-bottom p-0 me-4 border-0 overflow-hidden placeholder"
          style={{
            height: "200px",
            width: "300px",
          }}
          key={idx}
        />
      ))}
    </Row>
  );
}

function LoadingUserGreeting() {
  return (
    <span className="text-uppercase text-muted placeholder">Hello, foo!</span>
  );
}

function LoadedUserGreeting() {
  const user = useAsyncValue() as User;

  return <span className="text-uppercase text-muted">Hello, {user.name}!</span>;
}

export default function Home() {
  const response = useLoaderData() as { user: User; trips: Trip[] };
  const navigate = useNavigate();

  useEffect(() => {
    // Make overflow visible on mount
    document.documentElement.style.overflow = document.body.style.overflow =
      "visible";
    return () => {
      // Restore it on unmount
      document.documentElement.style.overflow = document.body.style.overflow =
        "hidden";
    };
  }, []);

  return (
    <div className="overflow-hidden">
      <div className="p-4 d-flex justify-content-between align-items-center">
        <React.Suspense fallback={<LoadingUserGreeting />}>
          <Await resolve={response.user}>
            <LoadedUserGreeting />
          </Await>
        </React.Suspense>
        <i
          className="bi bi-person-circle text-primary"
          style={{ fontSize: "1.5rem" }}
          onDoubleClick={() => navigate("/logout")}
        />
      </div>
      <section>
        <h1 className="px-4">Your trips</h1>
        <React.Suspense fallback={<LoadingTripCarousel />}>
          <Await resolve={response.trips}>
            <LoadedTripCarousel />
          </Await>
        </React.Suspense>
      </section>
      <section className="px-4 mt-4">
        <h1>Find what's next</h1>
        <Card
          className="w-100 d-flex flex-row align-items-center overflow-hidden border-0 mb-3"
          style={{ backgroundColor: "#eaf2ff" }}
          onClick={() => navigate("/explore")}
        >
          <div
            className="d-flex justify-content-center align-items-center flex-shrink-0"
            style={{
              width: "100px",
              height: "100px",
              backgroundColor: "#ffd4fb",
            }}
          >
            <i className="bi bi-stars fs-1" />
          </div>
          <div className="flex-grow-1 mx-3">
            <span className="fw-bold">Explore</span>
            <p className="text-muted mt-1 mb-0" style={{ fontSize: "0.9rem" }}>
              Best places to go based on your travel history
            </p>
          </div>
        </Card>
        <Card
          className="w-100 d-flex flex-row align-items-center overflow-hidden border-0 mb-3"
          style={{ backgroundColor: "#eaf2ff" }}
          onClick={() => navigate("/wizard")}
        >
          <div
            className="d-flex justify-content-center align-items-center flex-shrink-0"
            style={{
              width: "100px",
              height: "100px",
              backgroundColor: "#bcceff",
            }}
          >
            <i className="bi bi-magic fs-1" />
          </div>
          <div className="flex-grow-1 mx-3">
            <span className="fw-bold">Wizard</span>
            <p className="text-muted mt-1 mb-0" style={{ fontSize: "0.9rem" }}>
              Tell us what you are looking for and we'll make it happen
            </p>
          </div>
        </Card>
        <Card
          className="w-100 d-flex flex-row align-items-center overflow-hidden border-0 mb-4"
          style={{ backgroundColor: "#eaf2ff" }}
          //onClick={() => navigate("/search")}
        >
          <div
            className="d-flex justify-content-center align-items-center flex-shrink-0"
            style={{
              width: "100px",
              height: "100px",
              backgroundColor: "#fff2d4",
            }}
          >
            <i className="bi bi-search fs-1" />
          </div>
          <div className="flex-grow-1 mx-3">
            <span className="fw-bold">Search</span>
            <Badge
              pill
              className="ms-2"
              style={{
                fontSize: "0.6rem",
                verticalAlign: "bottom",
              }}
            >
              Coming soon
            </Badge>
            <p className="text-muted mt-1 mb-0" style={{ fontSize: "0.9rem" }}>
              Look for things to do in a specific place
            </p>
          </div>
        </Card>
      </section>
    </div>
  );
}

function LoadedTripCarousel() {
  const trips = useAsyncValue() as Trip[];

  return (
    <Row
      className="m-0 ps-4 flex-nowrap overflow-scroll hide-scrollbar"
      style={{ scrollbarWidth: "none" }}
    >
      {trips
        .filter((trip) => trip.isImported)
        .map((trip) => (
          <Card
            className="text-align-bottom p-0 me-4 border-0 overflow-hidden"
            style={{
              background: `url(${
                trip.destination.imageURI || placeholder
              }) center`,
              backgroundSize: "cover",
              height: "200px",
              width: "300px",
            }}
            key={trip.id}
          >
            <Card.Text
              className="mt-auto p-3 text-light"
              style={{
                background: "linear-gradient(0deg, black, transparent)",
              }}
            >
              <b>{trip.destination.name}</b>
              <br />
              {trip.destination.countryName}
            </Card.Text>
          </Card>
        ))}
    </Row>
  );
}
