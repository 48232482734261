import { useEffect, useState } from "react";
import {
  Button,
  Container,
  FloatingLabel,
  Form,
  ListGroup,
  ProgressBar,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  Form as RouterForm,
  Outlet,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import { Destination } from "./api";
import StarRating from "./StarRating";

type DestinationOption = {
  id: number;
  name: string;
  countryName: string;
};

interface RatedDestination extends Destination {
  rating?: number;
}

const Onboarding = {
  Root: () => {
    useEffect(() => {
      // Make overflow visible on mount
      document.documentElement.style.overflow = document.body.style.overflow =
        "visible";
      return () => {
        // Restore it on unmount
        document.documentElement.style.overflow = document.body.style.overflow =
          "hidden";
      };
    }, []);

    return (
      <>
        <Outlet />
      </>
    );
  },
  Introduction: () => {
    const name = useLoaderData() as string | null;

    return (
      <>
        <Container className="px-4">
          <ProgressBar now={15} className="my-4" style={{ height: "0.6rem" }} />
          <header className="mb-5">
            <h1>Hey there! 👋</h1>
            <h1>What's your name?</h1>
          </header>
          <RouterForm method="post">
            <Form.Control
              size="lg"
              type="text"
              placeholder="Your name"
              name="name"
              defaultValue={name || undefined}
              required
            />
            <Container className="fixed-bottom d-flex flex-column px-4 bg-body">
              <span className="text-muted" style={{ fontSize: "0.9rem" }}>
                By pressing "Next", I agree to the Terms of Service and Privacy
                Policy.
              </span>
              <Button type="submit" variant="primary" className="my-4 w-auto">
                Next
              </Button>
            </Container>
          </RouterForm>
        </Container>
      </>
    );
  },
  DestinationInput: () => {
    const userName = sessionStorage.getItem("onboarding.name")!;
    const sessionStorageDestinations = sessionStorage.getItem(
      "onboarding.destinations"
    );
    const storedDestinations =
      sessionStorageDestinations !== null
        ? JSON.parse(sessionStorageDestinations)
        : [];
    const availableDestinations = useLoaderData() as DestinationOption[];

    const navigate = useNavigate();

    return (
      <>
        <header className="sticky-top">
          <div className="bg-body pt-4 pb-2 px-4">
            <ProgressBar
              now={30}
              className="mb-4"
              style={{ height: "0.6rem" }}
            />
            <h1>Where have you been to, {userName}?</h1>
            <p className="text-muted">
              This will help us give you recommendations that are tailored just
              for you, based on your experiences.
            </p>
            <p className="text-muted mb-0">
              We recommend adding at least 5 destinations.
            </p>
          </div>
          <div className="airgap-top py-3" />
        </header>
        <Container className="px-4">
          <Form>
            <Typeahead
              id="destination-input"
              // @ts-ignore
              labelKey={(option) => `${option.name}, ${option.countryName}`}
              options={availableDestinations}
              placeholder="Type a city/town..."
              size="lg"
              maxResults={5}
              defaultSelected={storedDestinations}
              multiple
              autoFocus
              onChange={(options) =>
                sessionStorage.setItem(
                  "onboarding.destinations",
                  JSON.stringify(options)
                )
              }
            />
            <div className="py-5" />
            <Container className="fixed-bottom px-4">
              <div className="airgap-bottom py-3" />
              <div className="d-flex flex-column pb-4 bg-body">
                <Button
                  variant="link"
                  className="text-muted text-decoration-none"
                  onClick={() => navigate(-1)}
                >
                  <i className="bi bi-chevron-left me-1"></i>
                  Go back
                </Button>
                <Button
                  type="submit"
                  href="rate"
                  variant="primary"
                  className="mt-2 w-auto"
                >
                  Next
                </Button>
              </div>
            </Container>
          </Form>
        </Container>
      </>
    );
  },
  Rate: () => {
    const sessionStorageDestinations = sessionStorage.getItem(
      "onboarding.destinations"
    );
    const storedDestinations: RatedDestination[] =
      sessionStorageDestinations !== null
        ? JSON.parse(sessionStorageDestinations)
        : [];

    const [destinations, setDestinations] = useState(storedDestinations);

    const saveDestinations = () =>
      sessionStorage.setItem(
        "onboarding.destinations",
        JSON.stringify(destinations)
      );
    const navigate = useNavigate();

    return (
      <>
        <Container className="px-4">
          <header className="sticky-top">
            <div className="bg-body pt-4 pb-2">
              <ProgressBar
                now={60}
                className="mb-4"
                style={{ height: "0.6rem" }}
              />
              <h1>How did you like your trips?</h1>
              <p className="text-muted">
                This will help us give you recommendations that are tailored
                just for you, based on your experiences.
              </p>
              <p className="text-muted mb-0">
                We recommend rating as many destinations as possible.
              </p>
            </div>
            <div className="airgap-top py-3" />
          </header>
          <div className="d-flex flex-column overflow-scroll">
            <ListGroup className="mb-5">
              {storedDestinations.map((destination, i) => (
                <ListGroup.Item
                  className="py-3 d-flex justify-content-between align-middle"
                  key={`${destination.name}, ${destination.countryName}`}
                >
                  <span className="my-auto">
                    <strong>{destination.name}</strong>,{" "}
                    {destination.countryName}
                  </span>
                  <StarRating
                    count={4}
                    defaultValue={destination.rating}
                    onChange={(value) => {
                      setDestinations(
                        destinations.map((dst, j) =>
                          j === i ? { ...dst, rating: value } : dst
                        )
                      );
                    }}
                  />
                </ListGroup.Item>
              ))}
            </ListGroup>
            <div className="py-5" />
            <Container className="fixed-bottom px-4">
              <div className="airgap-bottom py-3" />
              <div className="d-flex flex-column pb-4 bg-body">
                <Button
                  variant="link"
                  className="text-muted text-decoration-none"
                  onClick={() => navigate(-1)}
                >
                  <i className="bi bi-chevron-left me-1"></i>
                  Go back
                </Button>
                <Button
                  onClick={() => {
                    saveDestinations();
                    navigate("../register");
                  }}
                  variant="primary"
                  className="mt-2 w-auto"
                >
                  Next
                </Button>
              </div>
            </Container>
          </div>
        </Container>
      </>
    );
  },
  Register: () => {
    const navigate = useNavigate();

    const defaultName = sessionStorage.getItem("onboarding.name") || "";

    const sessionStorageDestinations = sessionStorage.getItem(
      "onboarding.destinations"
    );
    const storedDestinations: RatedDestination[] =
      sessionStorageDestinations !== null
        ? JSON.parse(sessionStorageDestinations)
        : [];
    const ratings = storedDestinations.map(({ id, rating }) => ({
      id,
      rating,
    }));

    return (
      <Container className="px-4">
        <ProgressBar now={85} className="my-4" style={{ height: "0.6rem" }} />
        <header className="mb-5">
          <h1>Almost there!</h1>
          <p className="text-muted">
            Create an account to start receiving your first recommendations.
          </p>
        </header>
        <RouterForm method="post">
          <Form.Group>
            <FloatingLabel label="Name" className="mb-3">
              <Form.Control
                type="text"
                name="name"
                defaultValue={defaultName}
                required
              />
            </FloatingLabel>
            <FloatingLabel label="Email address" className="mb-3">
              <Form.Control
                type="email"
                name="email"
                placeholder="name@example.com"
                required
              />
            </FloatingLabel>
            <FloatingLabel label="Password" className="mb-3">
              <Form.Control
                type="password"
                name="password"
                placeholder="foo"
                required
              />
            </FloatingLabel>
            {ratings.map((rating) => (
              <input
                name="ratings"
                value={JSON.stringify(rating)}
                key={rating.id}
                readOnly
                hidden
              />
            ))}
          </Form.Group>
          <Container className="fixed-bottom d-flex flex-column px-4 py-4 bg-body">
            <Button
              variant="link"
              className="text-muted text-decoration-none"
              onClick={() => navigate(-1)}
            >
              <i className="bi bi-chevron-left me-1"></i>
              Go back
            </Button>
            <Button type="submit" variant="primary" className="mt-2 w-auto">
              Next
            </Button>
          </Container>
        </RouterForm>
      </Container>
    );
  },
};

export default Onboarding;
