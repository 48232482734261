import { Badge } from "react-bootstrap";

interface IProps {
  title: string;
  description: string;
  calendarTag: string;
  regularTags: string[];
  backgroundURI: string;
}

export default function StoryView(props: IProps) {
  const { title, description, calendarTag, regularTags, backgroundURI } = props;

  return (
    <div
      className="d-flex vw-100 vh-100"
      style={{
        background: `url(${backgroundURI}) center`,
        backgroundSize: "cover",
      }}
    >
      <div className="content mt-auto px-4 pb-4">
        <h1 className="text-light">{title}</h1>
        <div className="badge-list">
          <Badge pill bg="success" className="text-uppercase mb-2">
            <i
              className="bi bi-calendar-fill me-1"
              style={{ fontSize: "0.7rem" }}
              aria-label="heart"
            ></i>
            {calendarTag}
          </Badge>
          {regularTags.map((tag) => (
            <Badge pill key={tag} className="text-uppercase mb-2">
              {tag}
            </Badge>
          ))}
        </div>
        <p className="animation-wave text-light text-opacity-75 mb-2 pe-2">
          {description}
        </p>
        <span className="w-100 d-inline-block text-center text-muted">
          <i className="bi-chevron-double-up me-2" />
          Slide up for details
          <i className="bi-chevron-double-up ms-2" />
        </span>
      </div>
    </div>
  );
}
