import React from "react";
import { Alert, Button, Container } from "react-bootstrap";
import {
  isRouteErrorResponse,
  Navigate,
  useAsyncError,
  useRouteError,
} from "react-router-dom";

function normaliseResponseErrors(error: any): Error {
  if (isRouteErrorResponse(error)) return new Error(error.statusText);
  if (error instanceof Response) return new Error(error.statusText);
  return error;
}

export default function ErrorBoundary() {
  const syncError = useRouteError();
  const asyncError = useAsyncError();
  const incomingError = syncError || asyncError;
  console.error(incomingError);

  if (
    (isRouteErrorResponse(incomingError) ||
      incomingError instanceof Response) &&
    incomingError.status === 401
  ) {
    return (
      <Navigate to="/login" state={{ errorMessage: "Please log back in" }} />
    );
  }

  const error = normaliseResponseErrors(incomingError);

  return (
    <Container className="p-4 vh-100 d-flex flex-column">
      <h1>Uh-oh 😵‍💫</h1>
      <p>
        We did our best to do what you asked for, but looks like something has
        broken.
      </p>
      <p>
        Please let us know about this so that our team can make sure this does
        not happen again.
      </p>
      <Alert
        variant="dark"
        className="text-muted font-monospace"
        style={{ backgroundColor: "#e2e3e5" }}
      >
        <b>Type:</b> {error.name}
        <br />
        <b>Message:</b> {error.message}
      </Alert>
      <Button variant="primary" className="mt-auto" href="/home">
        Take me to the homescreen
      </Button>
    </Container>
  );
}
