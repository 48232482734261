import React from "react";
import { Alert, Badge, Button } from "react-bootstrap";

import OffcanvasCard, { IProps as OffcanvasCardIProps } from "./OffcanvasCard";

interface IProps extends OffcanvasCardIProps {
  onSubmit: () => void;
}

export default function FriendsSearchCard(props: IProps) {
  const { onSubmit, ...cardProps } = props;

  return (
    <OffcanvasCard
      summaryElement={
        <div className="d-flex justify-content-between fs-5 opacity-75">
          <b>FRIENDS</b>
          <span>Coming soon!</span>
        </div>
      }
      {...cardProps}
    >
      <div className="d-flex flex-column h-100">
        <h1 className="w-100 mb-3">
          <span className="me-2">Is anyone joining?</span>
          <Badge
            bg="success"
            className="align-middle"
            style={{ fontSize: "0.7rem" }}
          >
            Coming soon
          </Badge>
        </h1>
        <p className="text-muted">
          Get group recommendations based on the interest of your travelling
          party.
        </p>
        <Alert
          variant="dark"
          className="text-muted"
          style={{ backgroundColor: "#e2e3e5" }}
        >
          This feature will soon be available for all users – stay tuned!
        </Alert>
        <Button
          className="w-100 mt-auto"
          onClick={(e) => {
            onSubmit();
            e.stopPropagation();
          }}
        >
          Search
        </Button>
      </div>
    </OffcanvasCard>
  );
}
