import Hammer from "hammerjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Trip } from "./api";
import placeholder from "./assets/placeholder.png";
import SegmentedProgressBar from "./SegmentedProgressBar";
import StoryView from "./StoryView";

window.oncontextmenu = function (event) {
  event.preventDefault();
  event.stopPropagation();
  return false;
};

interface IProps {
  trip: Trip;
  autoNextPeriod: number; // ms
  active: boolean;
  onSwipeUp: () => any;
}

const CLICK_THRESHOLD = 200; // ms

const useElementOnScreen = (
  options: IntersectionObserverInit,
  targetRef: React.MutableRefObject<null>
) => {
  const [isVisibile, setIsVisible] = useState(true);

  const callbackFunction = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  const optionsMemo = useMemo(() => {
    return options;
  }, [options]);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, optionsMemo);
    const currentTarget = targetRef.current;
    if (currentTarget) observer.observe(currentTarget);

    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
  }, [targetRef, optionsMemo]);
  return isVisibile;
};

export default function TripStory(props: IProps) {
  const { trip, autoNextPeriod, onSwipeUp } = props;
  const { destination } = trip;

  const [timeInView, setTimeInView] = useState(0);
  const [currentView, setCurrentView] = useState(0);
  const [isOnHold, setIsOnHold] = useState(false);
  const [tapTimestamp, setTapTimestamp] = useState(Date.now());

  const ref = useRef(null);

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.85,
  };
  const isVisible = useElementOnScreen(options, ref);

  const numViews = trip.destination.attractions.length + 1;

  function onClick(event: React.TouchEvent<HTMLDivElement>) {
    const x = event.changedTouches[0].clientX;

    const viewportWidth = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );

    if (x < viewportWidth / 2) {
      return goToPreviousView();
    }
    return goToNextView();
  }

  useEffect(() => {
    setTimeInView(0);
    if (isVisible) {
      setCurrentView(0);
    }
  }, [isVisible]);

  const goToNextView = useCallback(() => {
    if (currentView < numViews - 1) {
      setCurrentView(currentView + 1);
      setTimeInView(0);
      return true;
    }
    return false;
  }, [currentView, numViews]);

  const goToPreviousView = useCallback(() => {
    setTimeInView(0);

    if (currentView > 0) {
      setCurrentView(currentView - 1);
      return true;
    }
    return false;
  }, [currentView]);

  useEffect(() => {
    const period = 10; // ms

    const timerId = setInterval(() => {
      if (isOnHold || !isVisible) return;

      const elapsedTime = timeInView + period;

      if (elapsedTime >= autoNextPeriod) {
        return goToNextView();
      }

      setTimeInView(elapsedTime);
    }, period);

    return () => {
      clearInterval(timerId);
    };
  });

  const views = [
    <StoryView
      title={`${destination.name}, ${destination.countryName}`}
      description={destination.description || "No description."}
      calendarTag={destination.duration || "Unknown"}
      regularTags={destination.tags}
      backgroundURI={destination.imageURI || placeholder}
    />,
    ...destination.attractions.map((attraction) => (
      <StoryView
        title={attraction.name}
        description={attraction.description || "No description."}
        calendarTag={attraction.duration || "Unknown"}
        regularTags={attraction.tags}
        backgroundURI={attraction.imageURI || placeholder}
      />
    )),
  ];

  useEffect(() => {
    if (ref.current === null) return;

    const mc = new Hammer.Manager(ref.current, {
      recognizers: [[Hammer.Swipe, { direction: Hammer.DIRECTION_VERTICAL }]],
    });

    mc.on("swipeup", onSwipeUp);
  }, [onSwipeUp]);

  return (
    <div
      className="vw-100 vh-100 position-relative trip-story"
      style={{
        scrollSnapAlign: "start",
        scrollSnapStop: "always",
      }}
      ref={ref}
      onTouchStart={(e) => {
        setIsOnHold(true);
        setTapTimestamp(Date.now());
      }}
      onTouchEnd={(e) => {
        if (Date.now() - tapTimestamp < CLICK_THRESHOLD) onClick(e);
        setIsOnHold(false);
      }}
    >
      <SegmentedProgressBar
        className="story-progress position-absolute px-2 pt-2 w-100 disable-transition"
        style={{ height: "5px" }}
        numSegments={numViews}
        currentSegment={currentView}
        progress={(timeInView / autoNextPeriod) * 100}
      />
      <div
        className="row m-0"
        style={{
          flexWrap: "nowrap",
        }}
      ></div>
      <div>
        {views.map((view, idx) => (
          <div key={idx} className={currentView === idx ? "d-block" : "d-none"}>
            {view}
          </div>
        ))}
      </div>
    </div>
  );
}

TripStory.defaultProps = {
  active: false,
};
