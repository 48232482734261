import { defer } from "react-router-dom";

export interface Trip {
  id: number;
  destinationId: number;
  rating: number | null;
  isRecommendation: boolean;
  isImported: boolean;
  destination: Destination;
}

export interface Destination {
  id: number;
  name: string;
  description: string | null;
  countryName: string;
  countryISO: string;
  latitude: number;
  longitude: number;
  population: number | null;
  imageURI: string | null;
  tags: string[];
  months: string[];
  duration: string | null;
  isEnriched: boolean;
  attractions: Attraction[];
}

export interface Attraction {
  id: number;
  name: string;
  description: string | null;
  imageURI: string | null;
  tags: string[];
  duration: string | null;
}

export interface User {
  id: string;
  name: string;
  email: string;
}

async function fetchJson(
  input: RequestInfo | URL,
  init?: RequestInit | undefined
): Promise<any> {
  const response = await fetch(input, init);
  if (!response.ok) throw response;
  return await response.json();
}

export function deferCall(call: (...args: any[]) => any) {
  return ({ request, params }: { request: Request; params: URLSearchParams }) =>
    defer({
      data: call(request, params),
    });
}

export async function login(email: string, password: string) {
  return await fetchJson(`/api/v1/auth/login`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
    }),
    method: "POST",
  });
}

export async function logout() {
  return await fetchJson(`/api/v1/auth/logout`, {
    headers: {
      Accept: "application/json",
    },
    method: "POST",
  });
}

export async function getDestinations() {
  return await fetchJson(`/api/v1/destinations`, {
    headers: {
      Accept: "application/json",
    },
    method: "GET",
  });
}

export interface DestinationRating {
  id: number;
  rating?: number;
}

export async function createUser(
  name: string,
  email: string,
  password: string,
  trips: DestinationRating[]
) {
  return fetchJson(`/api/v1/users`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      email,
      password,
      trips,
    }),
  });
}

export async function getTrips() {
  return fetchJson(`/api/v1/trips`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });
}

export async function getRecommendedTrips() {
  return fetchJson(`/api/v1/trips/recommended`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });
}

export async function getDestination(id: number) {
  return fetchJson(`/api/v1/destinations/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });
}

export async function getRecommendedTripsWithDetails(
  location?: string,
  duration?: number,
  tags?: string[]
) {
  const searchParams = new URLSearchParams();
  if (location) searchParams.append("location", location);
  if (duration) searchParams.append("duration", String(duration));
  tags?.forEach((tag) => searchParams.append("tags", tag));
  console.log(searchParams.toString());

  const trips = await fetchJson(`/api/v1/trips/recommended?${searchParams}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });

  return Promise.all(
    trips.map(async ({ destinationId, ...trip }: Trip) => ({
      ...trip,
      destination: await getDestination(destinationId),
    }))
  );
}

export async function getAllDestinationTags() {
  return fetchJson(`/api/v1/destinations/tags`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });
}

export async function getUser() {
  return fetchJson(`/api/v1/users`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });
}
