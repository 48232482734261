import React from "react";
import { Alert, Button, Container, FloatingLabel, Form } from "react-bootstrap";
import { Form as RouterForm, useLocation, useNavigate } from "react-router-dom";

export default function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const errorMessage = location.state?.errorMessage;

  return (
    <Container className="p-4 vh-100 d-flex flex-column">
      {!errorMessage ? null : <Alert variant="danger">{errorMessage}</Alert>}
      <div className="my-auto">
        <h1 onDoubleClick={() => navigate("/onboarding")}>Welcome!</h1>
        <p className="mb-4 text-muted">It's great to see you here.</p>
        <RouterForm className="text-dark" method="post">
          <FloatingLabel
            label="Email address"
            className="mb-3"
            style={{ color: "#707173", opacity: "1 !important" }}
          >
            <Form.Control
              type="email"
              name="email"
              placeholder="name@example.com"
              required
            />
          </FloatingLabel>
          <FloatingLabel
            label="Password"
            className="mb-3"
            style={{ color: "#707173", opacity: "1 !important" }}
          >
            <Form.Control
              type="password"
              name="password"
              placeholder="foo"
              required
            />
          </FloatingLabel>
          <Button
            variant="primary text-light"
            className="w-100 p-3"
            type="submit"
          >
            Log in
          </Button>
        </RouterForm>
      </div>
    </Container>
  );
}
