import React, { useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { Await, useAsyncValue, useLoaderData } from "react-router-dom";
import { Trip } from "./api";
import spinner from "./assets/spinner.svg";
import TripStory from "./TripStory";

function LoadingModal() {
  return (
    <>
      <div className="d-flex flex-column vw-100 vh-100 p-4 placeholder-glow">
        <div className="w-100 mt-auto">
          <img className="d-block mx-auto mb-5" src={spinner} alt="spinner" />
          <h3>Hang tight! 🤖</h3>
          <p className="mb-0">
            Our AI is preparing recommendations tailored just for you. This
            might take a couple of minutes.
          </p>
        </div>
        <div className="mt-auto w-100">
          <h1 className="placeholder-glow">
            <span className="placeholder col-3"></span>{" "}
            <span className="placeholder col-2"></span>
          </h1>
          <div className="badge-list mb-2">
            <Badge
              pill
              bg="success"
              className="text-uppercase placeholder d-inline-block col-2"
            ></Badge>
            {[...Array(3).keys()].map((tag) => (
              <Badge
                pill
                key={tag}
                className="text-uppercase placeholder d-inline-block col-1"
              ></Badge>
            ))}
          </div>
          <p className="text-muted mb-0">
            <span className="placeholder col-3 me-1"></span>
            <span className="placeholder col-5 me-1"></span>
            <span className="placeholder col-4 me-1"></span>
            <span className="placeholder col-6 me-1"></span>
            <span className="placeholder col-7 me-1"></span>
          </p>
        </div>
      </div>
      ,
    </>
  );
}

export default function Explore() {
  const response = useLoaderData() as { data: Trip[] };

  return (
    <React.Suspense fallback={<LoadingModal />}>
      <Await resolve={response.data}>
        <LoadedExplore />
      </Await>
    </React.Suspense>
  );
}

function LoadedExplore() {
  const trips = useAsyncValue() as Trip[];

  const [isSnapping, setIsSnapping] = useState(false);
  const [overlayTripId, setOverlayTripId] = useState<number | null>(null);

  const overlayTrip = trips.filter((trip) => trip.id === overlayTripId)[0];

  const overlayRef = useRef(null);

  const viewportWidth = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  useEffect(() => {
    if (overlayRef.current === null) return;

    const mc = new Hammer.Manager(overlayRef.current, {
      recognizers: [[Hammer.Swipe, { direction: Hammer.DIRECTION_VERTICAL }]],
    });

    mc.on("swipedown", () => setOverlayTripId(null));
  }, []);

  useEffect(() => {
    // Make overflow visible on mount
    document.documentElement.style.overflow = document.body.style.overflow =
      "visible";
    return () => {
      // Restore it on unmount
      document.documentElement.style.overflow = document.body.style.overflow =
        "hidden";
    };
  }, []);

  return (
    <>
      <Container
        className={`p-0 vh-100 overflow-auto explore d-flex flex-nowrap ${
          isSnapping ? "disable-scrolling" : ""
        }`}
        style={{ scrollSnapType: "x mandatory", overflowY: "hidden" }}
        onScroll={(event) =>
          setIsSnapping(
            (event.target as HTMLDivElement).scrollLeft % viewportWidth >
              0.9 * viewportWidth
          )
        }
      >
        {trips.map((trip) => (
          <TripStory
            trip={trip}
            autoNextPeriod={4000}
            key={trip.id}
            onSwipeUp={() => setOverlayTripId(trip.id)}
          />
        ))}
      </Container>
      <div
        className="p-4 h-100 w-100 border-0 position-absolute bg-white overflow-scroll"
        style={{
          top: overlayTripId !== null ? 0 : "100vh",
          transition: "0.2s ease-in-out",
        }}
        ref={overlayRef}
      >
        <div className="d-flex justify-content-between">
          <div className="mb-2">
            <span className="text-muted text-uppercase">Visiting</span>
            <h1>{overlayTrip?.destination.name}</h1>
          </div>
          <i
            className="bi bi-x-lg text-primary"
            style={{ fontSize: "1.5rem" }}
            onClick={() => setOverlayTripId(null)}
          />
        </div>
        <Row className="mt-3 gx-1">
          <Col className="me-2">
            <b>Start date</b>
            <Form.Control
              type="date"
              className="p-3"
              min={`${new Date().toISOString().split("T")[0]}`}
              defaultValue={`${new Date().toISOString().split("T")[0]}`}
            />
          </Col>
          <Col label="End date" className="w-100">
            <b>End date</b>
            <Form.Control
              type="date"
              className="p-3"
              defaultValue={`${new Date().toISOString().split("T")[0]}`}
            />
          </Col>
        </Row>
        <h2 className="mt-4">Getting there</h2>
        <InputGroup className="mb-3">
          <InputGroup.Text>From</InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="MAD"
            defaultValue="MAD"
            className="p-3"
          />
          <InputGroup.Text>To</InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="BCN"
            defaultValue="BCN"
            className="p-3"
            disabled
          />
        </InputGroup>

        <div className="d-flex flex-row py-4 align-items-center">
          <span className="p-4 fs-1">72€</span>
          <div className="vr"></div>
          <div className="d-flex flex-column px-4">
            <div className="w-100 mb-3 position-relative">
              <ProgressBar className="w-100 opacity-75">
                <ProgressBar variant="success" now={25} />
                <ProgressBar variant="warning" now={50} />
                <ProgressBar variant="danger" now={25} />
              </ProgressBar>
              <div
                className="bg-primary"
                style={{
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  position: "absolute",
                  top: "calc(50% - 10px)",
                  border: "3px solid white",
                  marginLeft: "calc(15% - 10px)",
                }}
              ></div>
            </div>
            <span>
              This trip is <b className="text-success opacity-75">cheaper</b>{" "}
              than usual for these dates
            </span>
          </div>
        </div>
        <Button variant="primary w-100">
          <div
            className="d-inline-block me-2"
            style={{ transform: "rotate(90deg)" }}
          >
            <i className="bi-airplane-fill" />
          </div>
          Search flights
        </Button>
        <h2 className="mt-4">Accomodation</h2>
        <div className="d-flex flex-row py-4 align-items-top">
          <div className="flex-fill px-4 text-center">
            <span className="fs-1 d-block">25€</span>
            <span
              className="text-muted d-block"
              style={{ fontSize: "0.85rem" }}
            >
              Budget
            </span>
          </div>
          <div className="vr"></div>
          <div className="flex-fill px-4 text-center">
            <span className="fs-1 d-block">68€</span>
            <span
              className="text-muted d-block"
              style={{ fontSize: "0.85rem" }}
            >
              Mid-range
            </span>
          </div>
          <div className="vr"></div>
          <div className="flex-fill px-4 text-center">
            <span className="fs-1 d-block">103€</span>
            <span
              className="text-muted d-block"
              style={{ fontSize: "0.85rem" }}
            >
              High end
            </span>
          </div>
        </div>
        <Button variant="primary w-100">
          <i className="bi-houses-fill me-2" />
          Search accomodation
        </Button>
      </div>
    </>
  );
}
