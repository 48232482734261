import React from "react";
import { Button, Form } from "react-bootstrap";
import OffcanvasCard, { IProps as OffcanvasCardIProps } from "./OffcanvasCard";

interface IProps extends OffcanvasCardIProps {
  value: number;
  onChange: (value: number) => void;
  onSubmit: () => void;
}

export default function DurationSearchCard(props: IProps) {
  const { value, onChange, onSubmit, ...cardProps } = props;

  function daysToString(days: number) {
    const weeks = days / 7;
    if (weeks < 1) {
      if (days === 1) {
        return `1 day`;
      } else {
        return `${days} days`;
      }
    } else {
      if (weeks === 1) {
        return `1 week`;
      } else {
        return `${weeks} weeks`;
      }
    }
  }

  return (
    <OffcanvasCard
      {...cardProps}
      summaryElement={
        <div className="d-flex justify-content-between fs-5 opacity-75">
          <b>Duration</b>
          <span>{daysToString(value)}</span>
        </div>
      }
    >
      <div className="d-flex flex-column h-100">
        <h1 className="w-100 mb-3">For how long?</h1>
        <Form.Select
          size="lg"
          onChange={(event) => onChange(Number(event.target.value))}
          defaultValue={value}
          className="mt-5"
        >
          <option value={1}>1 day</option>
          <option value={2}>2 days</option>
          <option value={3}>3 days</option>
          <option value={4}>4 days</option>
          <option value={5}>5 days</option>
          {
            //<option value={7}>1 week</option>
            //<option value={14}>2 weeks</option>
          }
        </Form.Select>
        <Button
          className="w-100 mt-auto"
          onClick={(e) => {
            onSubmit();
            e.stopPropagation();
          }}
        >
          Next
        </Button>
      </div>
    </OffcanvasCard>
  );
}
