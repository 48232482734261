import React, { useState } from "react";

interface IProps {
  count?: number;
  disabled?: boolean;
  defaultValue: number;
  onChange?: (value: number) => void;
}

export default function StarRating(props: IProps) {
  const { count, defaultValue, onChange } = props;
  const [value, setValue] = useState(defaultValue);

  const updateValue = (value: number) => {
    setValue(value);
    if (onChange) onChange(value);
  };

  return (
    <div className="d-block">
      {[...Array(count)].map((_, i) => (
        <i
          className={`text-primary bi ${
            i < value ? "bi-star-fill" : "bi-star"
          }`}
          style={{ fontSize: "1.3rem" }}
          aria-label="star"
          key={i}
          onClick={() => updateValue(i + 1)}
        ></i>
      ))}
    </div>
  );
}

StarRating.defaultProps = {
  count: 4,
  disabled: false,
  defaultValue: 0,
};
